




















































































































































































































































































































































































































import Vue from "vue";
import Errors from "../PdfSign/Errors.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import City from "@/models/City";
import store from "@/store/index";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifySuccess,
} from "@/components/Notification";
import rules from "@/components/account/rules";
import { AxiosError } from "axios";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { Lead } from "@/models/Lead";
import Worker from "@/models/Worker";
import { ErrorCRM } from "@/models/Errors";

export default Vue.extend({
  components: { Errors, MaDatePicker },
  name: "formd-demographics",
  data() {
    return {
      formValid: false,

      birthDateMenu: false,
      doctor: null,
      rules: {
        required: rules.required,
        email: rules.email,
        zip: rules.isZipCode,
        phone: rules.isPhone,
      },
      stateCities: [],
      loadingCities: false,
      state: 0,
      gender: "male",
      loading: false,
      showErrors: false,
      errorMessage: "",

      /*  how it finded us
      how do i find us */

      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      loadingmedical: false,
      form: {
        contactPreference: "PHONE",
        firstName: "",
        lastName: "",
        midleName: "",
        patientDoctor: "",
        sellerUuid: "",
        mailingCity: 0,
        mailingAddress: "",
        mailingZip: "",
        birthDate: "",
        medicalLocation: "",
        homePhone: "",
        preferLanguage: "ENGLISH",
        isMale: true,
        howKnow: "FACEBOOK",
        emergencyContact: "",
        emergencyPhone: "",
        emergencyRelation: "",
        email: "",
        weight: 0,
        height: 0,
        heightInches: 0,
        leadUuid: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getSellerN", "getpatientN"]),
    ...mapState("crmMedicFormModule", [
      "states",
      "loadingStates",
      "medicalLocations",
    ]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "agents",
      seller: "workers",
      coordinators: "coordinators",
    }),
    ...mapState("crmConfigurationsModule", ["leadSelect"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState(["profile"]),

    _seller() {
      let arrayseller: any = [];
      if (this.seller.length != 0) {
        arrayseller = this.seller.filter((s: Worker) => s.rol != "SUPER");
      }
      return arrayseller;
    },
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  async mounted() {
    this.loadingmedical = true;
    this.gender = "male";
    await this.actGetEmployees();
    await this.actGetCoordinators();

    if (this.leadSelect != null) {
      this.form.firstName = (this.leadSelect as Lead).name;
      this.form.lastName = (this.leadSelect as Lead).last_name;
      this.form.email = (this.leadSelect as Lead).email;
      this.form.homePhone = (this.leadSelect as Lead).phone.replace("+1", "");
      this.form.patientDoctor = (this.leadSelect as Lead).coordinator
        ? (this.leadSelect as Lead).coordinator.uuid
        : "";
    }
    await this.actListStates();

    this.state = 23;

    await this.actGetMedicalLocations();
    this.form.medicalLocation =
      this.medicalLocations.length != 0 ? this.medicalLocations[0].uuid : "";
    this.loadingmedical = false;
    this.actListReferences();
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",
      "actGetMedicalLocations",
      "actGetEducationLevels",
    ]),
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    create(): void {
      let { form: body } = this;
      let { birthDate, ...rest } = body;
      let ISObirdDate = new Date(birthDate).toISOString();
      if (this.leadSelect != null) {
        rest.leadUuid = this.leadSelect.uuid;
      }
      if (rest.homePhone) {
        rest.homePhone = "+1" + rest.homePhone;
      }
      if (rest.emergencyPhone) {
        rest.emergencyPhone = "+1" + rest.emergencyPhone;
      }
      if (rest.patientDoctor == "") {
        delete (rest as any).patientDoctor;
      }
      if (rest.sellerUuid == "") {
        rest.sellerUuid = store.state.profile.uuid;
      }
      if (
        rest.leadUuid == null ||
        rest.leadUuid == undefined ||
        rest.leadUuid == ""
      ) {
        delete (rest as any).leadUuid;
      }

      this.loading = true;

      getAPI
        .post("/patient/create", { ...rest, birthDate: ISObirdDate })
        .then((res) => {
          notifySuccess(this.$t("medicForm.notificationPatientCreated"));
          this.showErrors = false;
          (this as any).mutleadSelect(null);
          this.$refs.patientForm.reset();
          this.loading = false;
          this.$router.push({
            name: "Patient ddetails",
            params: { uuid: res.data.uuid },
          });
        })
        .catch((error: any) => {
          this.loading = false;
          const errorResponse: ErrorCRM = error.response.data;
          let message = "Error: ";
          errorResponse.message.forEach((m) => {
            message = message + m + ", ";
          });
          message = message.substr(0, message.length - 1);
          notifyError(message);
        });
    },

    reset(): void {
      this.$refs.patientForm.reset();
    },
    cancel() {
      this.$refs.patientForm.reset();
      this.loading = false;
      this.$router.push({ name: "Patient list" });
    },
  },
  destroyed() {
    (this as any).mutleadSelect(null);
  },
});
